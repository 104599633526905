export const esOperationFilter = {
  title: 'Operación de edición',
  subTitle: 'La operación se aplicará a la página de visitas',
  button: {
    operation: 'Operación',
    cancel: 'Cancelar',
    save: 'Guardar',
    tooltip: 'La operación incluye a la organización y al vendedor',
  },
  fields: {
    country: {
      label: 'País',
      placeholder: 'Selecione un País',
    },
    organization: {
      label: 'Organización',
      hint: 'Puede incluir empresas y país',
      placeholder: 'Seleccione una organización',
      alert: 'Este país no tiene organizaciones',
    },
    seller: {
      label: 'Vendedor',
      hint: 'Quien recibirá la tarea',
      placeholder: 'Selecciona un vendedor',
    },
    vendorId: {
      LABEL: 'ID del vendedor',
      placeholder: 'Selecione un ID del vendedor',
    },
  },
};
