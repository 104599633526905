import { enUSOperationFilter } from '@/components/OperationFilter/i18n/en-US';
import { enUSNotFound } from '@/pages/Page404/i18n/en-US';
import { enUSPageError } from '@/pages/PageError/i18n/en-US';
import { enUSLoading } from '@/pages/PageLoading/i18n/en-US';
import { enUSTaskList } from '@/pages/TaskList/i18n/en-US';

export const enUS = {
  test: {
    translate: 'Translate',
    missing: 'Only English option available',
  },
  pages: {
    taskList: enUSTaskList,
    notFound: enUSNotFound,
    pageError: enUSPageError,
    loading: enUSLoading,
  },
  component: {
    operationFilter: enUSOperationFilter,
  },
};
