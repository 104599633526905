import { ReactNode, useEffect } from 'react';

import { axiosInstance } from '@bees-force-shared/services';

import { useOperationFilter } from '@/components/OperationFilter/hooks/useOperationFilter';

interface InitializerProviderProps {
  children: ReactNode;
}

export function InitializerProvider({ children }: InitializerProviderProps) {
  const { operationData } = useOperationFilter(undefined);

  useEffect(() => {
    axiosInstance.defaults.headers['vendorGroupId'] = operationData?.vendorGroupId || '';
  }, [operationData]);

  return <>{children}</>;
}
