import { ReactNode, useCallback } from 'react';
import { MessageFormatElement, IntlProvider as NativeProvider, useIntl } from 'react-intl';

import { usePreferredLanguageV2 } from 'admin-portal-shared-services';
import { FormatXMLElementFn, PrimitiveType } from 'intl-messageformat';
import { Options as IntlMessageFormatOptions } from 'intl-messageformat/src/core';

import { enUS } from '@/i18n/languages/en-US';
import { es419 } from '@/i18n/languages/es-419';
import { ptBR } from '@/i18n/languages/pt-BR';

import { MessageMap, TranslationKeys } from './i18n.d';

const DEFAULT_LANGUAGE = 'en-US';
type IIntlProvider = {
  children: ReactNode;
  // Mostly for Testing purposes
  locale?: string;
};

export const messages: { [language: string]: MessageMap } = {
  'en-US': enUS,
  'pt-BR': ptBR,
  'es-419': es419,
};

export function IntlProvider({ children, locale }: IIntlProvider): JSX.Element {
  const { preferredLanguage, defaultLanguage } = usePreferredLanguageV2();

  const mergedMessages = mergeMessages(messages, preferredLanguage as string);

  return (
    <NativeProvider
      locale={locale ?? (preferredLanguage as string)}
      defaultLocale={defaultLanguage}
      messages={mergedMessages}>
      {children}
    </NativeProvider>
  );
}

/* eslint-disable @typescript-eslint/explicit-module-boundary-types, @typescript-eslint/no-explicit-any */
export const flattenObject = (ob: any): any => {
  const toReturn: { [key: string]: any } = {};

  for (const i in ob) {
    if (typeof ob[i] === 'object' && ob[i] !== null) {
      const flatObject = flattenObject(ob[i]);
      // eslint-disable-next-line guard-for-in
      for (const x in flatObject) {
        toReturn[`${i}.${x}`] = flatObject[x];
      }
    } else {
      toReturn[i] = ob[i];
    }
  }
  return toReturn;
};
/* eslint-enable */

export const mergeMessages = (
  messagesInput: MessageMap,
  selectedLocale: string,
): Record<string, string> | Record<string, MessageFormatElement[]> => {
  const defaultMessages = flattenObject(messagesInput[DEFAULT_LANGUAGE]);
  const localeMessages = flattenObject(messagesInput[selectedLocale]);
  return { ...defaultMessages, ...localeMessages };
};

export const useTranslate = () => {
  const { formatMessage } = useIntl();

  const t = useCallback(
    (
      key: TranslationKeys,
      values?: Record<string, PrimitiveType | FormatXMLElementFn<string, string>>,
      opts?: IntlMessageFormatOptions,
    ) => formatMessage({ id: key }, values, opts),
    [formatMessage],
  );

  return { t };
};
