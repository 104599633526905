export const enUSOperationFilter = {
  title: 'Edit operation',
  subTitle: 'The operation will be applied to the task page',
  button: {
    operation: 'Operation',
    cancel: 'Cancel',
    save: 'Save',
    tooltip: 'The operation includes the organization and seller',
  },
  fields: {
    country: {
      label: 'Country',
      placeholder: 'Select a country',
    },
    organization: {
      label: 'Organization',
      hint: 'May include companies and country',
      placeholder: 'Select an organization',
      alert: 'This country has no organizations',
    },
    seller: {
      label: 'Seller',
      placeholder: 'Select a seller',
      hint: 'Who will receive the task',
    },
    vendorId: {
      label: 'Vendor ID',
      placeholder: 'Select a vendor ID',
    },
  },
};
