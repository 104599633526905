import { useCallback, useEffect, useState } from 'react';

import { $userSelection } from '@bees-force-shared/services';
import { Vendor } from 'admin-portal-shared-services';

import { OperationData } from '../types/OperationFilterTypes';

type Operations = {
  parentGroupId: string;
  name: string;
  country: string;
  vendors?: Vendor[];
  id: string;
  childGroups?: Operations[];
};

type Country$1 = {
  country: string;
  error: boolean;
};

type OperationsResponse = {
  content: Operations[];
  countries: Country$1[];
};
export const useOperationFilter = (operations?: { data?: OperationsResponse }) => {
  const [isEditOperationModalOpen, setIsEditOperationModalOpen] = useState(false);
  const [currentOperation, setCurrentOperation] = useState('Country / Company / Seller');
  const [operationData, setOperationData] = useState<OperationData>({
    country: '',
    organization: { id: '', name: '' },
    seller: { id: '', name: '' },
    vendorGroupId: '',
  });

  const group = operations?.data?.content?.[0];
  const parentGroupIdEqualsToRoot = group?.parentGroupId === 'root';

  const getChildGroup = useCallback((group) => group?.childGroups?.[0] || null, [0]);

  const getDefaultSeller = useCallback(() => {
    if (!group) return undefined;

    if (!parentGroupIdEqualsToRoot) {
      const childGroup = getChildGroup(group);
      return childGroup ? { id: childGroup.id, name: childGroup.name } : undefined;
    }

    const nestedChildGroup = getChildGroup(getChildGroup(group));
    return nestedChildGroup ? { id: nestedChildGroup.id, name: nestedChildGroup.name } : undefined;
  }, [group, parentGroupIdEqualsToRoot, getChildGroup]);

  const handleDefineOperationLabel = useCallback(() => {
    const countryLabel = operationData.country ? `${operationData.country}` : '';
    const organizationLabel = operationData.organization?.name
      ? `/ ${operationData.organization?.name}`
      : '';
    const sellerLabel = operationData.seller?.name ? `/ ${operationData.seller?.name}` : '';

    return `${countryLabel} ${organizationLabel} ${sellerLabel}`;
  }, [operationData.country, operationData.organization, operationData.seller]);

  useEffect(() => {
    const operationLabel = handleDefineOperationLabel();

    setCurrentOperation(operationLabel);
  }, [handleDefineOperationLabel]);

  useEffect(() => {
    if (!operationData.organization.id && operations?.data?.countries) {
      const defaultCountry = operations?.data?.countries?.[0]?.country || '';
      const child = getChildGroup(group);
      const defaultOrganization = parentGroupIdEqualsToRoot
        ? { id: child.id, name: child.name }
        : { id: group?.id, name: group?.name };

      const defaultSeller = getDefaultSeller();

      let operation = 'Country / Company / Seller';
      if (defaultSeller && defaultOrganization.id && defaultCountry) {
        operation = `${defaultCountry} / ${defaultOrganization.name} / ${defaultSeller.name}`;
      } else if (defaultOrganization.name && defaultCountry) {
        operation = `${defaultCountry} / ${defaultOrganization.name}`;
      }

      setCurrentOperation(operation);
      setOperationData({
        country: defaultCountry,
        organization: defaultOrganization,
        seller: defaultSeller,
        vendorGroupId: defaultSeller?.id || defaultOrganization?.id,
      });
    }
  }, [
    getChildGroup,
    group,
    getDefaultSeller,
    operationData.organization.id,
    operations?.data?.content,
    operations?.data?.countries,
    parentGroupIdEqualsToRoot,
  ]);

  useEffect(() => {
    $userSelection.watch((userSelection) => {
      if (userSelection) {
        const { operation } = userSelection;
        const operationData = {
          country: operation.country,
          organization: { id: operation.organization?.id, name: operation.organization?.name },
          seller: { id: operation.seller?.id, name: operation.seller?.name },
          vendorId: operation.vendorId,
          vendorGroupId: operation.seller?.id || operation.organization?.id,
        };

        setOperationData(operationData);
      }
    });
  }, []);

  const handleCancel = () => {
    setIsEditOperationModalOpen(false);
  };

  const handleConfirm = (operationData: OperationData) => {
    let operation = 'Country / Company / Seller';

    if (operationData.seller && operationData.organization.id && operationData.country) {
      operation = `${operationData.country} / ${operationData.organization.name} / ${operationData.seller.name}`;
    } else if (operationData.organization.name && operationData.country) {
      operation = `${operationData.country} / ${operationData.organization.name}`;
    }

    const vendorGroupId = operationData?.seller?.id || operationData?.organization?.id;

    setCurrentOperation(operation);
    setOperationData({ ...operationData, vendorGroupId });
  };

  const handleOpenModal = () => setIsEditOperationModalOpen(true);

  return {
    isEditOperationModalOpen,
    currentOperation,
    operationData,
    handleConfirm,
    handleOpenModal,
    handleCancel,
  };
};
