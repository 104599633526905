export const ptBROperationFilter = {
  title: 'Operação de edição',
  subTitle: 'A operação será aplicada à página de visitas',
  button: {
    operation: 'Operação',
    cancel: 'Cancelar',
    save: 'Salvar',
    tooltip: 'A operação inclui a organização e o vendedor',
  },
  fields: {
    fields: {
      label: 'País',
      placeholder: 'Selecione um País',
    },
    Organization: {
      label: 'Organização',
      hint: 'Pode incluir empresas e país',
      placeholder: 'Selecione uma organização',
      alert: 'Este país não tem organizações',
    },
    Seller: {
      label: 'Vendedor',
      hint: 'Quem receberá a tarefa',
      placeholder: 'Selecione um vendedor',
    },
    VendorId: {
      label: 'Vendor ID',
      placeholder: 'Selecione um vendor ID',
    },
  },
};
