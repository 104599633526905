import { LoadingBuzz } from '@hexa-ui/components';
import { DocumentTitle } from 'admin-portal-shared-services';

import { useTranslate } from '@/i18n/provider';

import { ContainerPage, PageLoadingContainer } from './PageLoading.styles';

export const PageLoading = (): JSX.Element => {
  const { t } = useTranslate();

  return (
    <ContainerPage>
      <DocumentTitle>{t('pages.loading.title')}</DocumentTitle>
      <PageLoadingContainer>
        <LoadingBuzz data-testid="loading-buz-component" size="xlarge" />
      </PageLoadingContainer>
    </ContainerPage>
  );
};
