import {
  AnalyticsWrapper,
  OptimizelyWrapper,
  QueryWrapper,
} from '@bees-force-shared/business-components';
import { ThemeProvider } from '@bees-force-shared/theme';

import { EnvProvider } from '@/components/EnvProvider/EnvProvider';
import { EnvConfig } from '@/components/EnvProvider/EnvProvider.types';
import { Router } from '@/components/Router/Router';
import { IntlProvider } from '@/i18n/provider';
import { InitializerProvider } from '@/providers/Initializer';

function App(props: EnvConfig) {
  return (
    <EnvProvider env={props}>
      <IntlProvider>
        <OptimizelyWrapper sdkKey={props.OPTIMIZELY_KEY ?? ''}>
          <AnalyticsWrapper segmentKey={props.SEGMENT_KEY ?? ''}>
            <ThemeProvider>
              <QueryWrapper>
                <InitializerProvider>
                  <Router />
                </InitializerProvider>
              </QueryWrapper>
            </ThemeProvider>
          </AnalyticsWrapper>
        </OptimizelyWrapper>
      </IntlProvider>
    </EnvProvider>
  );
}

export { App };
