import { lazy, Suspense } from 'react';
import { RouteObject } from 'react-router-dom';

import { PATH_TASK_LIST } from '@/consts/Routes';
import { Page404 } from '@/pages/Page404/Page404';
import { PageError } from '@/pages/PageError/PageError';
import { PageLoading } from '@/pages/PageLoading/PageLoading';

const TaskList = lazy(() =>
  import('@/pages/TaskList/TaskList').then((module) => ({ default: module.TaskList })),
);

const PageFallback = <PageLoading />;

export const RouteNotPermission = (errorMessage: string): RouteObject[] => [
  {
    path: '*',
    element: (
      <Suspense fallback={PageFallback}>
        <PageError error={errorMessage} onRetry={() => window.location.reload()} />
      </Suspense>
    ),
  },
];

export const RoutesDefinitions = [
  {
    path: PATH_TASK_LIST,
    element: (
      <Suspense fallback={PageFallback}>
        <TaskList />
      </Suspense>
    ),
  },
  {
    path: '/*',
    element: (
      <Suspense fallback={PageFallback}>
        <Page404 />
      </Suspense>
    ),
  },
];
