import { useNavigate } from 'react-router-dom';

import { Error404 } from '@hexa-ui/components';
import { DocumentTitle } from 'admin-portal-shared-services';

import { PATH_HOME } from '@/consts/Routes';
import { useTranslate } from '@/i18n/provider';

import { ContainerPage, PageErrorWrapper } from './Page404.styles';

export const Page404 = (): JSX.Element => {
  const navigate = useNavigate();
  const { t } = useTranslate();

  return (
    <ContainerPage>
      <DocumentTitle>{t('pages.notFound.title')}</DocumentTitle>
      <PageErrorWrapper data-testid="fallback-notfound-page">
        <Error404
          buttonClickHandler={() => {
            navigate(PATH_HOME);
          }}
          buttonText={t('pages.notFound.backToHome')}
          headerText={t('pages.notFound.messageError')}
          subHeaderText={t('pages.notFound.errorNotFoundSuggestion')}
        />
      </PageErrorWrapper>
    </ContainerPage>
  );
};
